<template>
  <div>
    <Sidebar />
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            <h1>Minha conta</h1>
          </div>
        </div>

        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-receipt"></i>
              <h3>Alterar senha</h3>
            </div>
            <div class="row">
              <div class="col-md-6 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label"> Nova senha </label>
                  <input v-model="new_password" type="password" class="form-control" name="passnew"
                    placeholder="Digite a nova senha." />
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label"> Repetir nova senha </label>
                  <input v-model="renew_password" type="password" class="form-control" name="repassnew"
                    placeholder="Repita a  nova senha." />
                </div>
              </div>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-end mt-5">
              <button class="btn btn-primary btn-all" @click="chance">
                <span>Alterar</span>
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>

import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoLink,
  Autosave,
  BalloonToolbar,
  BlockQuote,
  Bold,
  Code,
  CodeBlock,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  HorizontalLine,
  Indent,
  IndentBlock,
  Italic,
  Link,
  Paragraph,
  RemoveFormat,
  SelectAll,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextPartLanguage,
  TextTransformation,
  Underline,
  Undo
} from 'ckeditor5';

import translations from 'ckeditor5/translations/pt-br.js';

import 'ckeditor5/ckeditor5.css';

export default {

  components: {
    NavBar,
    Sidebar,
  },
  data() {
    return {
      name: "",
      email: "",
      tarifa: 0,
      minWP: 0,
      maxWP: 0,
      minInsolation: 0,
      maxInsolation: 0,
      wpp: "",
      status: "",
      author: "",
      token: "",
      taxes: [],
      new_password: '',
      renew_password: '',
      editorData: '',
      editorDataLegal: '',
      isLayoutReady: false,
      config: null,
      editor: ClassicEditor,
      is_legal_entity: 0
    };
  },
  methods: {
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {
        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        text: textMsg,
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
    },
    load() {
      this.$http.get("admin/tax", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.tarifa = response.data.tax.tarifa.toFixed(2);
            this.minWP = response.data.tax.minWP.toFixed(2);
            this.maxWP = response.data.tax.maxWP.toFixed(2);
            this.minInsolation = response.data.tax.minInsolation.toFixed(2);
            this.maxInsolation = response.data.tax.maxInsolation.toFixed(2);
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    chance() {
      if (this.new_password != this.renew_password) {
        this.showAlert("error", "A nova senha e a confirmação não estão iguais.");
        return;
      }

      var json = {
        old: this.old_password,
        new: this.new_password,
        renew: this.renew_password,
      }

      this.$http.post("admin/change", json, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          this.showAlert("success", response.data.message);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    chanceDoc() {
      if (!this.editorData) return this.showAlert("error", "A procuração está em branco!");

      var json = {
        type: this.is_legal_entity,
        doc: (this.is_legal_entity == 1)? this.editorData : this.editorDataLegal,
      }

      this.$http.post("admin/doc", json, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          this.showAlert("success", response.data.message);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    tax() {
      var json = {
        tarifa: this.tarifa,
        minWP: this.minWP,
        maxWP: this.maxWP,
        minInsolation: this.minInsolation,
        maxInsolation: this.maxInsolation
      }

      this.$http.post("admin/tax", json, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          this.showAlert("success", response.data.message);
          this.load();
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    chanceLogo() {
      console.log("logo")
      var json = {
        type: 1,
        documentUpload: this.$refs.logotipo.files[0],
      }

      this.$http.post("admin/upload", json, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          this.showAlert("success", response.data.message);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
  },
  created() {
    if (Cookie.get('_token')) {

      this.token = Cookie.get('_token')
    }
    this.load();
  },
  mounted() {

    this.config = {
      toolbar: {
        items: [
          'undo',
          'redo',
          '|',
          'findAndReplace',
          'textPartLanguage',
          '|',
          'heading',
          '|',
          'fontSize',
          'fontFamily',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'subscript',
          'superscript',
          'code',
          'removeFormat',
          '|',
          'specialCharacters',
          'horizontalLine',
          'link',
          'insertTable',
          'highlight',
          'blockQuote',
          'codeBlock',
          '|',
          'alignment',
          '|',
          'outdent',
          'indent'
        ],
        shouldNotGroupWhenFull: true
      },
      plugins: [
        AccessibilityHelp,
        Alignment,
        Autoformat,
        AutoLink,
        Autosave,
        BalloonToolbar,
        BlockQuote,
        Bold,
        Code,
        CodeBlock,
        Essentials,
        FindAndReplace,
        FontBackgroundColor,
        FontColor,
        FontFamily,
        FontSize,
        Heading,
        Highlight,
        HorizontalLine,
        Indent,
        IndentBlock,
        Italic,
        Link,
        Paragraph,
        RemoveFormat,
        SelectAll,
        SpecialCharacters,
        SpecialCharactersArrows,
        SpecialCharactersCurrency,
        SpecialCharactersEssentials,
        SpecialCharactersLatin,
        SpecialCharactersMathematical,
        SpecialCharactersText,
        Strikethrough,
        Subscript,
        Superscript,
        Table,
        TableCaption,
        TableCellProperties,
        TableColumnResize,
        TableProperties,
        TableToolbar,
        TextPartLanguage,
        TextTransformation,
        Underline,
        Undo
      ],
      balloonToolbar: ['bold', 'italic', '|', 'link'],
      fontFamily: {
        supportAllValues: true
      },
      fontSize: {
        options: [10, 12, 14, 'default', 18, 20, 22],
        supportAllValues: true
      },
      heading: {
        options: [
          {
            model: 'paragraph',
            title: 'Paragraph',
            class: 'ck-heading_paragraph'
          },
          {
            model: 'heading1',
            view: 'h1',
            title: 'Heading 1',
            class: 'ck-heading_heading1'
          },
          {
            model: 'heading2',
            view: 'h2',
            title: 'Heading 2',
            class: 'ck-heading_heading2'
          },
          {
            model: 'heading3',
            view: 'h3',
            title: 'Heading 3',
            class: 'ck-heading_heading3'
          },
          {
            model: 'heading4',
            view: 'h4',
            title: 'Heading 4',
            class: 'ck-heading_heading4'
          },
          {
            model: 'heading5',
            view: 'h5',
            title: 'Heading 5',
            class: 'ck-heading_heading5'
          },
          {
            model: 'heading6',
            view: 'h6',
            title: 'Heading 6',
            class: 'ck-heading_heading6'
          }
        ]
      },
      language: 'pt-br',
      link: {
        addTargetToExternalLinks: true,
        defaultProtocol: 'https://',
        decorators: {
          toggleDownloadable: {
            mode: 'manual',
            label: 'Downloadable',
            attributes: {
              download: 'file'
            }
          }
        }
      },
      table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
      },
      translations: [translations]
    };

    this.isLayoutReady = true;

  }
};
</script>
