import { createRouter, createWebHistory } from 'vue-router'

import Guard from '@/services/middleware'

import Login from '@/views/LoginView.vue'
import HomeView from '@/views/HomeView.vue'

import SettingsView from '@/views/SettingsView.vue'
import EngineersListView from '@/views/Engineers/EngineersListView.vue'
import EngineersAddView from '@/views/Engineers/EngineersAddView.vue'
import EngineersManagerView from '@/views/Engineers/EngineersManagerView.vue'

import FinancialListView from '@/views/Financial/FinancialListView.vue'
import LeadsListView from '@/views/Leads/LeadsListView.vue'
import CalculatorView from '@/views/Leads/CalculatorEngView.vue'
import CalculatorIntegrationView from '@/views/Leads/CalculatorIntegrationView.vue'

import MyDoc from '@/views/MyDoc.vue'
import PlansAddView from '@/views/Plans/PlansAddView.vue'
import PlansListView from '@/views/Plans/PlansListView.vue'
import PlansManagerView from '@/views/Plans/PlansManagerView.vue'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: Guard.login,
  },
  {
    path: '/calculator/:uuid',
    name: 'calculator',
    component: CalculatorView,
  },
  {
    path: '/calculator/:uuid/integration',
    name: 'calculator_integration',
    component: CalculatorIntegrationView,
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/config',
    name: 'settings',
    component: SettingsView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/engenheiros',
    name: 'Engineers',
    component: EngineersListView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/engenheiros/adicionar',
    name: 'user-add',
    component: EngineersAddView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/engenheiros/gerenciar/:user',
    name: 'user-manager',
    component: EngineersManagerView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/planos',
    name: 'plans',
    component: PlansListView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/planos/adicionar',
    name: 'plans-add',
    component: PlansAddView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/planos/gerenciar/:plan',
    name: 'plans-manager',
    component: PlansManagerView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/financeiro',
    name: 'financial',
    component: FinancialListView,
    beforeEnter: Guard.auth,
  },
  {
    path: '/procuracao',
    name: 'procuracao',
    component: MyDoc,
    beforeEnter: Guard.auth,
  },
  {
    path: '/leads',
    name: 'leads',
    component: LeadsListView,
    beforeEnter: Guard.auth,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router