<template>
    <main class="login calculator">
        <div class="box">
            <div class="title">
                <img class="logo-name" :src="baseStorage + logo" alt="Logo">
            </div>
            <div class="calculator-text">
                <h5 class="text">Calculadora Solar</h5>
                <small class="description">Simule seu gerador de Energia Solar</small>
            </div>
            <div class="inputs-login">
                <div v-show="!callback" class="row">
                    <div class="col-12">
                        <div class="form-input mb-2">
                            <label for="formGroupExampleInput" class="form-label">Nome</label>
                            <input v-model="name" type="text" class="form-control" placeholder="Qual o seu nome?">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-input mb-2">
                            <label for="formGroupExampleInput" class="form-label">Telefone</label>
                            <input v-maska="'(##) #####-####'" v-model="phone" class="form-label" placeholder="Seu telefone" />
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-input mb-2">
                            <label for="formGroupExampleInput" class="form-label">Valor da Conta</label>
                            <money3 v-model="spent" v-bind="config"></money3>
                        </div>
                    </div>
                    <div class="col-md-6 col-12">
                        <div class="form-input mb-2">
                            <label for="formGroupExampleInput" class="form-label">CEP</label>
                            <input v-model="cep" @input="search" type="text" class="form-control"
                                placeholder="Digite seu CEP">
                        </div>
                    </div>
                    <div class="col-md-6 col-12">
                        <div class="form-input mb-2">
                            <label for="formGroupExampleInput" class="form-label">Estado</label>
                            <input v-model="uf" type="text" placeholder="UF" class="form-control" readonly>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="w-100 d-flex align-items-center justify-content-center mt-5">
                            <button href="#" class="btn btn-primary btn-all w-100" @click="simule">Simular Kit
                                Solar</button>
                        </div>
                    </div>
                </div>
                <div v-show="callback" class="row">
                    <div class="col-12">
                        <div class="form-input mb-2">
                            <label for="formGroupExampleInput" class="form-label">VALOR DE INVESTIMENTO</label>
                            <label for="formGroupExampleInput" class="form-label desc-lead">{{ valueKIT }}</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-input mb-2">
                            <label for="formGroupExampleInput" class="form-label">PAYBACK ESTIMADO MÁXIMO</label>
                            <label for="formGroupExampleInput" class="form-label desc-lead">{{ payback }}</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-input mb-2">
                            <label for="formGroupExampleInput" class="form-label">POTÊNCIA DO KIT</label>
                            <label for="formGroupExampleInput" class="form-label desc-lead">{{ pKIT }}</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="w-100 d-flex align-items-center justify-content-center mt-5">
                            <a :href="'https://api.whatsapp.com/send?phone=' + phoneCalculator + '&text=Quero%20realizar%20um%20orçamento%20de%20KIT%20Solar.%0A%0A*Dados%20da%20Simulação*%0AValor%20de%20Investimento%3A%20R$' + valueKIT + '%0APayback%3A%20' + payback + '%0AKIT%3A%20' + pKIT + '%0AValor%20da%20Conta%3A%20R$' + spent"
                                class="btn btn-primary btn-all w-100" target="_blank">Solicitar Orçamento</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </main>
</template>

<script>

export default {
    components: {

    },
    data() {
        return {
            phoneCalculator: '',
            logo: '',
            name: '',
            phone: '',
            spent: '',
            cep: '',
            uf: '',
            country: '',
            callback: false,
            info: [],
            uuid: this.$route.params.uuid,
            valueKIT: '',
            payback: '',
            pKIT: '',
            config: {
                masked: false,
                prefix: 'R$ ',
                suffix: '',
                thousands: '.',
                decimal: ',',
                precision: 2,
                disableNegative: false,
                disabled: false,
                min: null,
                max: null,
                allowBlank: false,
                minimumNumberOfCharacters: 0,
                shouldRound: true,
                focusOnRight: false,
            }
        }
    },
    methods: {
        showAlert(type, msg) {
            var textMsg = "";

            if (msg.message) {
                if (typeof msg.errors === 'object' && msg.errors) {
                    for (const key in msg.errors) {
                        if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
                            const errorMessages = msg.errors[key];
                            textMsg += `${errorMessages.join(', ')}<br>`;
                        }
                    }
                } else {
                    textMsg = `${msg.message}`;
                }
            } else {
                textMsg = `${msg}`;
            }

            this.$swal({
                text: textMsg,
                icon: type,
                confirmButtonText: 'Confirmar',
                html: textMsg
            });
        },
        simule() {

            var json = {
                name: this.name,
                phone: this.country + this.phone,
                spent: this.spent,
                cep: this.cep,
                uf: this.uf,
                uuid: this.$route.params.uuid
            };

            if (
                !this.name ||
                !this.phone ||
                !this.spent ||
                !this.cep ||
                !this.uf ||
                !this.uuid
            ) {
                return this.showAlert('error', 'Preencha todos os dados.');
            }

            this.$http
                .post("/leads", json, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }).then((response) => {
                    this.info = response.data.info;

                    this.valueKIT = response.data.info.valueKIT;
                    this.payback = response.data.info.payback;
                    this.pKIT = response.data.info.pKIT;

                    this.callback = true;
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        this.showAlert('error', error.response.data);
                    } else {
                        this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
                    }
                });
        },
        search() {
            if (this.cep.length < 8 || this.cep.length > 8) {
                return;
            }

            this.$http.get("/cep/" + this.cep)
                .then((response) => {
                    this.uf = response.data.uf;
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        this.showAlert('error', error.response.data);
                    } else {
                        this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
                    }
                });
        },
        load() {
            this.$http.get("/calculator/" + this.$route.params.uuid)
                .then((response) => {
                    this.logo = response.data.logo;
                    this.phoneCalculator = response.data.phone;
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        this.showAlert('error', error.response.data);
                    } else {
                        this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
                    }
                });
        },
        onUpdate(payload) {
            this.country = payload.countryCallingCode
        },
    },
    created() {
        this.load();
    },
    computed: {
        resultsTable() {
            return Object.keys(this.results)
        },
    },
}
</script>
